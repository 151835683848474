import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import validation from './validate'


Vue.config.productionTip = false;


import router from './router';
import {store} from './store/index';

import Api from './api.js';

window.api = new Api();
window.Event = new Vue();
// filters
import './filters/xmoney';

// directives
import './directives/xmoney';

// load app styles
import './assets/sass/app.scss';

import App from './App';
import Loader from './components/loader';
Vue.component('loader', Loader);

import Notify from './components/notify';
Vue.component('notify', Notify);

export default new Vue({
  router,
  store,
  data() {
    return {
      app_url: process.env.VUE_APP_API_URL
    }
  },
  render: h => h(App),
}).$mount('#app');