<template>
    <div class="d-flex" v-if="loaded">
        <pg :user="user" v-if="!error"/>
        <not-found v-else />
    </div>
    <loader v-else/>
</template>

<script>
    import pg from '../components/box-payment'
    import NotFound from "../components/not-found";

    export default {
        name: 'index',
        components: {
            NotFound,
            pg
        },
        created() {
            this.getUser();
        },
        data() {
            return {
                loaded: false,
                error: false,
                message: '',
                user: {},
                form: {
                    username: this.$route.params.username,
                    amount: null,
                    name: null,
                    description: null,
                },
                paying: false,
            }
        },
        methods: {
            getUser() {
                window.api.call('post', '/me/get', {username: this.$route.params.username}, {}, true).then(({data}) => {
                    this.user = data.data.user;
                    document.title = data.data.user.name;
                    this.error = false;
                    this.loaded = true;
                }).catch((e) => {
                    let {data} = e;
                    if (data.errors) {
                        this.error = true;
                        this.message = data.message;
                    }
                    this.loaded = true;
                });
            },

        }
    }
</script>
