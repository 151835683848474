import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {  required  } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);

Vue.component("ValidationObserver", ValidationObserver);

extend("required", {
    ...required,
    message: "این فیلد اجباری می باشد."
});

