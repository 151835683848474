<template>
    <div class="not-found d-flex justify-content-center align-items-center flex-column">
        <div class="not-found-wrapper d-flex justify-content-center align-items-center">
            <p class="not-found-code font-medium">404</p>
        </div>
        <p class="not-found-description font-fa">
            لینکی که دنبالش می گردی وجود نداره
        </p>
        <div class="mt-2 mt-sm-5">
            <a-button label="بازگشت به صفحه اصلی" color="blue" @click.native="gotToSite"/>
        </div>
    </div>
</template>

<script>
    import AButton from "./button/index";

    export default {
        name: "notFound",
        components: {
            AButton
        },
        methods: {
            gotToSite() {
                window.location.href = process.env.VUE_APP_URL
            }
        }
    }
</script>

