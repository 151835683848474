<template>
  <ValidationProvider
    class="d-inline-block w-100"
    mode="passive"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <div
      class="a-input-wrapper font-fa "
      :class="{
        'd-flex align-items-center has-badge': badge !== '',
        disabled: disabled,
        active: innerValue === '0' || innerValue === '0' || innerValue !== undefined && innerValue !== '',
        'is-error': errors[0]
      }"
    >
      <input
        v-if="inputMode === 'simple'"
        :class="[
          'font-light a-input mode-bg-1 mode-text-4 mode-border-2 has-hover has-focus ',
          { ltr: isLtr }
        ]"
        :name="$attrs.name"
        :type="type"
        v-model="innerValue"
        :state="errors[0] ? false : valid ? true : null"
        :disabled="disabled"
        @blur="isFocus = false"
        @focus="isFocus = true"
        @keydown="dataMask"
        :maxlength="maxlength"
        :autocomplete="$attrs.autocomplete"
      />
      <input
        v-if="inputMode === 'number'"
        class="font-light a-input mode-bg-1 mode-text-4 mode-border-2 has-hover has-focus ltr"
        :name="$attrs.name"
        :type="type"
        v-model="innerValue"
        v-xnumber
        :state="errors[0] ? false : valid ? true : null"
        :disabled="disabled"
        @blur="blurFix(), (isFocus = false)"
        @focus="isFocus = true"
        @keydown="dataMask"
        :maxlength="maxlength"
      />
      <input
        v-if="inputMode === 'money'"
        :class="[
          'font-light a-input mode-bg-1 mode-text-4 mode-border-2 has-hover has-focus ',
          { ltr: isLtr, 'has-request-action': requestAction }
        ]"
        :type="type"
        v-model="innerValue"
        :state="errors[0] ? false : valid ? true : null"
        :disabled="disabled"
        @blur="isFocus = false"
        @focus="isFocus = true"
        :maxlength="maxlength"
        v-xmoney
      />
      <input
        v-if="inputMode === 'card'"
        :class="[
          'a-input font-light mode-bg-1 mode-text-4 mode-border-2 has-hover has-focus ',
          { ltr: isLtr }
        ]"
        :type="type"
        v-model="innerValue"
        :state="errors[0] ? false : valid ? true : null"
        :disabled="disabled"
        @blur="isFocus = false"
        @focus="isFocus = true"
        :maxlength="maxlength"
        v-xcard
      />
      <span
        class="a-input-badge d-flex align-items-center justify-content-center mode-border-2"
        v-if="badge !== ''"
      >
        {{ badge }}
      </span>
      <label class="a-input-placeholder mode-text-3">
        {{ placeholder }}
      </label>
      <label
        class="a-input-bag-mask mode-text-3"
        v-show="!innerValue || innerValue === ''"
      >
        {{ bagMask }}
      </label>
      <span
        class="a-input-example mode-text-3"
        v-html="example"
        v-if="!errors[0]"
      />
      <span
        class="a-input-request-action mode-text-5"
        v-if="requestAction"
        @click="$emit('inputAction')"
        >{{ requestAction }}</span
      >
      <span class="a-input-question mode-text-5" v-if="!errors[0]">
        {{ question }}
      </span>
      <span class="a-input-error" v-if="errors[0]">
        {{ errors[0] }}
      </span>

      <div
        class="a-input-tooltip"
        :class="{ active: isFocus }"
        v-html="tooltip"
        v-if="tooltip"
      ></div>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: "a-input",
  props: {
    value: null,
    inputMode: {
      type: String,
      default: "simple"
    },
    requestAction: {
      type: String,
      default: null
    },
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    maxlength: {
      type: [Number, String],
      default: ""
    },
    isEn: {
      type: Boolean,
      default: false
    },
    hasErrIcon: {
      type: Boolean,
      default: false
    },
    example: {
      type: String,
      default: ""
    },
    badge: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    question: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    bagMask: {
      type: String,
      default: ""
    },
    isLtr: {
      type: Boolean,
      default: false
    },
    isDate: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text",
      validator: val => {
        return (
          ["hidden", "url", "text", "password", "email", "search"].indexOf(
            val
          ) !== -1
        );
      }
    }
  },
  data() {
    return {
      innerValue: "",
      isFocus: false
    };
  },
  watch: {
    innerValue(newVal) {
      if (this.inputMode === "number") {
        this.$emit("input", this.faToEn(newVal) ? this.faToEn(newVal) : "");
      } else {
        this.$emit("input", newVal);
      }
    },
    value(newVal) {
      if (this.inputMode === "number") {
        // let elm = document.querySelector(`[name=${this.vid}] input`);
        this.innerValue = this.faToEn(newVal) ? this.faToEn(newVal) : "";
      } else {
        this.innerValue = newVal;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    blurFix() {
      this.innerValue = this.faToEn(this.innerValue);
    },
    dataMask(e) {
      if (!this.isDate) return;
      if (e.key === "Backspace" || e.code === "Slash" || e.key === "/") return;
      let v = this.innerValue;
      if (!/(\d)$/.test(v)) return;
      if (v.match(/^\d{4}$/) !== null) {
        this.innerValue = this.faToEn(v) + "/";
      } else if (v.match(/^\d{4}\/\d{2}$/) !== null) {
        this.innerValue = this.faToEn(v) + "/";
      }
    }
  }
};
</script>
