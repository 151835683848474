<template>
    <div
            class="notify-wrapper d-flex flex-column align-items-center justify-content-between"
            :class="[optionShow.color, { active: show }]"
            v-if="optionShow"
    >
        <div class="d-flex align-items-center">
            <div class="notify-text pt-1" v-html="optionShow.text">

            </div>
            <div class="notify-icon" @click="hiddenNotify">
                <img
                        :src="require('@/img/' + optionShow.icon + '.svg')"
                        :alt="'icon-' + mode"
                />
            </div>
        </div>
        <div class="notify-action mt-3" v-if="optionShow.action">
            <a-button
                    color="white"
                    :label="optionShow.action"
                    @click.native="$emit('actionNotify', optionShow.actionName)"
            />
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            msg: {
                type: String,
                default: ""
            },
            action: {
                type: String,
                default: null
            },
            actionName: {
                type: String,
                default: null
            },
            mode: {
                type: String,
                default: "clear",
                validator: function (value) {
                    return (
                        ["clear", "success", "warning", "error", "info"].indexOf(value) !== -1
                    );
                }
            }
        },
        data() {
            return {
                show: false,
                timeNotify: null,
                optionShow: null,
                types: {
                    error: {
                        typeId: "error",
                        color: "error",
                        icon: "error",
                        text: ""
                    },
                    success: {
                        typeId: "success",
                        color: "success",
                        icon: "success",
                        text: ""
                    },
                    warning: {
                        typeId: "warning",
                        color: "warning",
                        icon: "warning",
                        text: ""
                    },
                    info: {
                        typeId: "info",
                        color: "info",
                        icon: "info",
                        text: ""
                    },
                    clear: {
                        typeId: "clear",
                        color: "",
                        icon: "info",
                        text: ""
                    }
                }
            };
        },
        methods: {
            hiddenNotify() {
                if (this.optionShow && this.optionShow.typeId === "error") {
                    this.showNotify({
                        visible: false,
                        type: "clear",
                        text: "",
                        action: null,
                        actionName: this.actionName
                    });
                    this.$emit("hideNotify");
                }
            },
            showNotify({visible, type, text}) {
                this.types[type].text = text;
                this.optionShow = this.types[type];
                this.show = visible;
                clearTimeout(this.timeNotify);
                if (!visible) return;
                this.timeNotify = setTimeout(() => {
                    this.showNotify({
                        visible: false,
                        type: "clear",
                        text: "",
                    });
                }, 12000);
            }
        }
    };
</script>
