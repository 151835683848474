export default {
  namespaced: true,
  state: () => ({
    data: {
      show: false,
      mode: "success",
      msg: "",
      action: null,
      actionName: null
    }
  }),
  actions: {},
  mutations: {
    SET_DATA(state, { id, data }) {
      state[id] = data;
    }
  }
};
