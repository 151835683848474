<template>
    <div id="app" class="light h-100 w-100">
        <div class="container h-100 align-items-center justify-content-center d-flex">
            <router-view/>
        </div>
        <notify
                :visible="notify.show"
                :is-auto="false"
                :mode="notify.mode"
                :msg="notify.msg"
                :action="notify.action"
                ref="elNotify"
        />
    </div>
</template>

<script>
    export default {
        name: 'layout',
        computed: {
            notify() {
                return this.$store.state.notify.data;
            },
        },
        watch:{
            notify(val, old) {
                if (val.show) {
                    if (old.show) {
                        this.$refs.elNotify.showNotify({visible: false, type: "clear", text: ""})
                    }
                    setTimeout(() => {
                        this.$refs.elNotify.showNotify({visible: true, type: val.mode, text: val.msg})
                    }, 200)
                } else {
                    this.$refs.elNotify.showNotify({visible: false, type: "clear", text: ""})
                }
            }
        }
    }
</script>