<template>
    <div class="server-error d-flex justify-content-center align-items-center flex-column">
        <div class="server-error-wrapper d-flex justify-content-center align-items-center">
            <div class="d-flex align-items-center flex-column">
                <span class="server-error-code font-medium py-3">{{error.code}}</span>
                <p class="server-error-msg font-medium">{{error.title}}</p>
            </div>

        </div>
        <p class="mt-sm-5 pt-md-4 server-error-description font-fa">
            {{error.message}}
        </p>
        <div class="mt-2 mt-sm-5">
            <a-button label="بازگشت به صفحه اصلی" color="blue" @click.native="gotToSite"/>
        </div>
    </div>
</template>

<script>
    import AButton from "./button/index";

    export default {
        name: "server-error",
        props: {
            error: {
                type: Object,
                default: () => {
                    return {
                        title: 'خطا در انجام تراکنش',
                        code: '505',
                        message:"سیستم با خطا مواجه شده است دقایقی بعد مجدد تلاش کنید"
                    }
                }
            }
        },
        components: {
            AButton
        },
        methods: {
            gotToSite() {
                window.location.href = 'https://pay-frontend.web.app'
            }
        }
    }
</script>

