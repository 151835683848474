<template>
    <div class="box-pay d-flex flex-column">
        <div class="d-flex flex-column flex-md-row">
            <div class="box-pay-body rtl">
                <ValidationObserver
                        class="login-top"
                        ref="observer"
                        v-slot="{ handleSubmit }"
                    >
                    <div
                            class="box-pay-header w-100 d-flex align-items-center justify-content-center"
                            :style="'background-color:' + activeColor"
                    >
                        <div
                                class="box-pay-avatar d-flex align-items-center justify-content-center"
                                :style="{backgroundImage:`url(${srcAvatar})`}"
                        >

                        </div>
                        <div class="box-pay-info mr-3 d-flex flex-column  justify-content-between">
                            <p
                                    class="box-pay-title align-self-top"
                                    :class="{ 'dark-text': activeColor === '#ebebeb' }"
                            >
                                {{ user.name }}
                            </p>
                            <p class="box-pay-link ltr" :class="{ 'dark-text': activeColor === '#ebebeb' }">
                              Me.pay.ir/{{ user.username }}
                            </p>
                        </div>
                    </div>
                    <div
                            class="box-description d-flex align-items-center justify-content-center"
                    >
                        <p>
                            پرداخت وجه به <span>{{ user.name }}</span>
                        </p>
                    </div>
                    <div class="w-100 mt-3">
                        <a-input
                                name="amount"
                                vid="amount"
                                type="text"
                                class="mb-3"
                                inputMode="money"
                                v-model="price"
                                :is-ltr="true"
                                placeholder="مبلغ پرداختی (ريال)"
                        />
                        <span class="box-price-show" v-if="price">
                            {{ price | toToman }}
                        </span>
                    </div>
                    <div class="w-100 mt-3">
                        <a-input
                                name="name"
                                vid="name"
                                type="text"
                                class="mb-3"
                                v-model="name"
                                placeholder="نام پرداخت کننده"
                        />
                    </div>
                    <div class="mt-3">
                        <a-textarea placeholder="توضیحات (اختیاری)" v-model="description"/>
                    </div>
                    <div class="mt-3 w-100">
                        <a-button
                                :disabled="name === '' || price === ''"
                                :is-block="true" :has-spinner="showLoader" label="پرداخت امن"
                                @click.native="handleSubmit(pay)">
                            <slot>
                                <img src="@/img/lock-white.svg" alt="lock-white" class="ml-2"/>
                            </slot>
                        </a-button>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <a href="https://pay.ir" target="_blank" class="footer">
             <img src="@/img/logo.svg" alt="شبکه پرداخت پی"/>
            <p class="font-fa">قدرت گرفته از شبکه پرداخت پی</p>
        </a>
    </div>
</template>

<script>
    import AButton from "./button/index";
    import AInput from "./input/index"
    import ATextarea from "./input/textarea"


    String.prototype.toEnglishDigits = function () {
        let id = {'۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9'};
        return this.replace(/[^0-9.]/g, function (w) {
            return id[w] || w;
        });
    };

    export default {
        name: "payment-personal-demo",
        props: {
            username: {
                type: String,
                default: ""
            },
            isShow: {
                type: Boolean,
                default: false
            },
            user: {
                type: Object,
                default: () => {
                }
            }
        },
        components: {
            AButton,
            AInput,
            ATextarea
        },
        data() {
            return {
                showLoader: false,
                name: "",
                price: "",
                amount: "",
                description: "",
                listColor: {
                    blue: "#5098ef",
                    purple: "#8b8ef2",
                    gray: "#ebebeb",
                    black: "#555555",
                    "#5098ef": "blue",
                    "#8b8ef2": "purple",
                    "#ebebeb": "gray",
                    "#555555": "black"
                }
            };
        },
        filters: {
            toToman(val, rial_support = true, rial_to_toman_divide_rate = 10) {
                if (!val) return "";
                let num = val.split(",").join("");
                num = num.toEnglishDigits();
                // number separate strings
                let number_separate_str = [
                    "",
                    " هزار",
                    " میلیون",
                    " میلیارد",
                    " بیلیون",
                    " بیلیارد",
                    " تریلیون",
                    " تریلیارد",
                    "کوآدریلیون",
                    " کادریلیارد",
                    " کوینتیلیون",
                    " کوانتینیارد",
                    " سکستیلیون",
                    " سکستیلیارد",
                    " سپتیلیون",
                    "سپتیلیارد",
                    " اکتیلیون",
                    " اکتیلیارد",
                    " نانیلیون",
                    " نانیلیارد",
                    " دسیلیون",
                    " دسیلیارد"
                ];

                // convert price to number
                num = Number(num);

                // return empty string if price is not valid
                if (!num) return '';

                // save remaining and convert price from rial to toman
                let divide_remaining = 0;
                if (rial_support) {
                    divide_remaining = num % rial_to_toman_divide_rate;
                    num = Math.floor(num / rial_to_toman_divide_rate);
                }

                // init text
                let text_num = [], text = '';

                // fill text_num array by price parts
                Number(num)
                    .toLocaleString('en-EG')
                    .split(',')
                    .reverse()
                    .map((item, index) => {
                        if (Number(item)) text_num.unshift(`${Number(item)} ${number_separate_str[index]}`);
                    });

                text = text_num.join(' و ');
                text && text.length >= 1 ? text += ' تومان ' : '';

                // handle remaining rial
                if (rial_support && divide_remaining !== 0) text += text && text.length >= 1 ? `و ${divide_remaining} ریال ` : `${divide_remaining} ریال`;

                return text;
            }
        },
        computed: {
            activeColor() {
                return this.listColor[this.theme];
            },
            theme() {
                return this.user.theme || "blue";
            },
            srcAvatar() {
                if (!this.user.avatar) {
                    return require('@/img/profile/' + this.theme + '.svg')
                } else {
                    return this.user.avatar;
                }
            },
            avatar() {
                return this.user.avatar;
            }
        },
        methods: {
            validationBackEnd(error, cb) {
                let _err = error.data.errors;
                for (const property in _err) {
                    cb(property, _err[property]);
                }
            },
            pay() {
                this.showLoader = true;
                window.api.call('post', '/me/pay', {
                    username: this.user.username,
                    name: this.name,
                    amount: this.price,
                    description: this.description
                }, {}, true).then(({data}) => {
                    window.location.href = data.data.url;
                }).catch((error) => {
                    this.validationBackEnd(
                        error,
                        function (key, val) {
                            if (["amount", "description"].includes(key)) {
                                this.$refs.observer.setErrors({
                                    [key]: val
                                });
                            } else {
                                this.$refs.observer.setErrors({
                                    amount: val
                                });
                            }

                        }.bind(this)
                    );
                    this.showLoader = false;
                });
            }
        }
    };
</script>
