<template>
  <ValidationProvider
    mode="passive"
    class="d-inline-block w-100"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <div class="a-textarea-wrapper font-light">
      <textarea
        class="a-textarea mode-bg-1 p-2 mode-text-4 mode-border-2 has-hover has-focus font-light"
        v-model="innerValue"
        :state="errors[0] ? false : valid ? true : null"
        :class="{ 'is-error': errors[0] }"
        :maxlength="$attrs.maxlength"
      >
      </textarea>
      <label
        class="a-textarea-placeholder mode-text-3"
        v-show="innerValue == ''"
      >
        {{ placeholder }}
      </label>
      <span class="a-textarea-error">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: "a-textarea",
  props: {
    value: null,
    placeholder: String,
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    }
  },
  data() {
    return {
      innerValue: ""
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", this.isEn ? this.faToEn(newVal) : newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
