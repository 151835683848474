<template>
    <div v-if="loaded" class="rtl">
        <div v-if="!error">
            <div v-if="transferInfo.status">
                <div class="result-wrapper mode-bg-2 pt-2 text-center">
                    <div class="py-2 result-status mx-4 mode-border-3">
                        <img
                                src="@/img/success-form.svg"
                                class="result-image"
                                alt="ok"
                        />
                        <p class="font-bold font-16 mt-1 mb-3 mode-text-7">
                            تراکنش با موفقیت انجام شد
                        </p>
                    </div>
                    <div class="d-flex flex-column px-4 mx-1 mt-4 py-4">
                        <div class="d-flex  mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                شماره تراکنش :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.id }}
                              </span>
                        </div>

                      <div class="d-flex mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                واریز کننده :
                              </span>
                        <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.sender }}
                              </span>
                      </div>

                      <div class="d-flex mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                دریافت کننده :
                              </span>
                        <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.receiver }}
                              </span>
                      </div>

                        <div class="d-flex mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                تاریخ انجام :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.created_at }}
                              </span>
                        </div>
                        <div class="d-flex  mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                مبلغ ( ریال ) :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.amount | xmoney }}
                              </span>
                        </div>
                        <div class="d-flex  mb-4 justify-content-between align-items-start" v-if="transferInfo.description">
                          <span class="font-fa font-14 mode-text-7 ">
                                توضیحات :
                          </span>
                            <span class="font-light font-13 mode-text-1 text-wrap result-description text-justify ">
                                {{transferInfo.description}}
                        </span>
                        </div>
                    </div>

                </div>
                <div class="result-footer">
                    <img src="@/img/l-Resid.svg" alt="پرداخت موفق"/>
                </div>
            </div>
            <div v-if="!transferInfo.status">
                <div class="result-wrapper mode-bg-2  pt-3   text-center">
                    <div class="py-3 result-status mx-4 mode-border-3">
                        <img
                                src="@/img/error-form.svg"
                                class="result-error"
                                alt="error"
                        />
                        <p class="font-bold font-16 mt-3 mb-3 mode-text-7">
                            تراکنش موفقیت آمیز نبود
                        </p>
                    </div>
                    <div class="d-flex flex-column px-4 mx-1 mt-2 py-4">
                        <div class="d-flex  mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                شماره تراکنش :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.id }}
                              </span>
                        </div>
                        <div class="d-flex  mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                تاریخ تراکنش :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.created_at }}
                              </span>
                        </div>
                        <div class="d-flex  mb-4 justify-content-between align-items-center">
                              <span class="font-fa font-14 mode-text-7">
                                مبلغ به ریال :
                              </span>
                            <span class="font-light font-13 mode-text-1">
                                {{ transferInfo.amount | xmoney }}
                              </span>
                        </div>
                        <a-button :is-block="true" label="تلاش مجدد" @click.native="getPagePay"/>
                    </div>

                </div>
                <div class="result-footer">
                    <img src="@/img/l-Resid.svg" alt="پرداخت موفق"/>
                </div>
            </div>

            <div class="receipt-footer d-flex align-items-center flex-column justify-content-center mt-5">
                <img src="@/img/logo.svg" alt=""/>
                <p>قدرت گرفته از شبکه پرداخت پی</p>
            </div>
        </div>
        <div v-else>
            <server-error :error="dataError" v-if="dataError.status !== 404"/>
            <not-found v-else />
        </div>
    </div>
    <div v-else>
        <loader/>
    </div>
</template>

<script>
    import ServerError from "../components/server-error";
    import AButton from "../components/button/index";
    import NotFound from "../components/not-found";
    import loader from "../components/loader"

    export default {
        name: "Receipt",
        components: {NotFound, AButton, ServerError,loader},
        created() {
            this.getTransaction();
        },
        data() {
            return {
                success: false,
                loaded: false,
                error: false,
                message: '',
                transferInfo: {},
                dataError: {}
            }
        },
        methods: {
            validationBackEnd(error, cb) {
                let _err = error.data.errors;
                for (const property in _err) {
                    cb(property, _err[property]);
                }
            },
            getPagePay() {
                window.location.href = '/' + this.$route.params.username
            },
            getTransaction() {
                window.api.call('post', '/me/receipt', {
                    username: this.$route.params.username,
                    token: this.$route.params.token
                }, {}, true).then(({data}) => {
                    this.transferInfo = data.data.transaction;
                    this.loaded = true;
                    document.title = 'رسید پرداخت به' + ' ' + this.transferInfo.receiver;
                }).catch((error) => {
                    if (error) {
                        let {status} = error;
                        if (status === 404) {
                            this.dataError = {
                                title:"",
                                code: 404,
                                message: "صفحه مورد نظر یافت نشد"
                            }
                        } else if  (status === 500) {
                            this.dataError = {
                                title:"خطا در انجام تراکنش !",
                                code: 500,
                                message: "سیستم با خطا مواجه شده است لطفا مجدد تلاش کنید"
                            }
                        }else if (status === 422) {
                            this.validationBackEnd(
                                error,
                                function (key, val) {
                                    this.dataError = {
                                        code: error.status,
                                        message: val.join('')
                                    }
                                }.bind(this)
                            );
                        }
                        this.error = true;
                    }
                    this.loaded = true;
                });
            }
        }
    }
</script>

