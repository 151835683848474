import Vue from 'vue';
import Router from 'vue-router';

import Index from './views/Index';
import Receipt from './views/Receipt';
import NotFound from './components/not-found';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/:username',
      name: 'Index',
      component: Index
    },
    {
      path: '/:username/receipt/:token',
      name: 'Receipt',
      component: Receipt
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
  ],
  mode: 'history',
})
