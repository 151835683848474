<template>
  <div
    class="a-button-wrapper font-fa"
    :class="{ 'w-100': isBlock, 'hide-action': showLoader }"
  >
    <button
      class="a-button has-hover"
      :class="[
        mode + ' mode-' + color + '-' + mode,
        { 'w-100': isBlock, 'a-button-loader': showLoader }
      ]"
      :disabled="disabled"
    >
      <slot></slot>
      <span>
        {{ label }}
      </span>
      <span class="a-button-spinner spinner-border text-light" role="status">
        <p class="sr-only" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "a-button",
  props: {
    label: {
      type: String,
      default: "ثبت"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasSpinner: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "green",
      validator: val => {
        return (
          [
            "gray",
            "white",
            "blue-light",
            "green",
            "blue",
            "red",
            "silver"
          ].indexOf(val) !== -1
        );
      }
    },
    mode: {
      type: String,
      default: "fill",
      validator: val => {
        return ["fill", "line"].indexOf(val) !== -1;
      }
    }
  },
  computed: {
    showLoader() {
      return this.hasSpinner;
    }
  },
  watch: {
    isLoaderBtn(val) {
      this.isSpinner = val;
    }
  }
};
</script>
