<template>
  <div style="width: 100%; text-align: center">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  }
</script>