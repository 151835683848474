import axios from 'axios';
import {store} from './store/';

class Api {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
  }

  call(requestType, url, data = null, headers = [], r = false) {
    return new Promise((resolve, reject) => {
      let finalUrl = null;
      if (/^(f|ht)tps?:\/\//i.test(url)) {
        finalUrl = url
      } else {
        finalUrl = this.baseUrl + url
      }
      axios[requestType](finalUrl, data, headers).then(response => {
        resolve(response);
      }).catch((e) => {
        let response = e.response;
        if (response.status === 422 && !r) {
          let _err = response.data.errors;
          for (const property in _err) {
            store.commit("notify/SET_DATA", {
              id: "data",
              data: {
                show: true,
                mode: "error",
                msg: _err[property].join('')
              }
            });

          }

          // window.toastr.error(response.data.message);
        }
        if (r) {
          reject(response);
        }
        return e;
      });
    });
  }
}

export default Api;