import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// modules
import notify from "./notify";

export const store = new Vuex.Store({
    state: {
    },
    strict: false,
    modules: {
        notify
    },
    mutations: {

    }
});
